<template>
  <Container space-before space-after class="print:hidden">
    <Gallery
      :lazy-load="lazyLoad"
      :images="images"
      :in-narrow-content="isInNarrowContent"
      :column-data="columnData"
    />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      images() {
        return this.element.nestedElements.map((el) => ({
          id: el.id,
          size: el.ingredients.find((i) => i.role === "size").value,
          srcset: el.ingredients.find((i) => i.role === "image").srcset,
          src: el.ingredients.find((i) => i.role === "image").value,
          alt: el.ingredients.find((i) => i.role === "image").altText,
        }))
      },
      isInNarrowContent() {
        return this.$attrs["data-learning-recipe-with-sidebar"]
      },
      columnData() {
        if (this.$attrs["data-column"] === "true") {
          return {
            columnLayout: this.$attrs["data-column-layout"],
            columnPlacement: this.$attrs["data-column-placement"],
          }
        } else {
          return null
        }
      },
      lazyLoad() {
        return this.element.position > 3
      },
    },
  }
</script>
