<template>
  <div class="gallery">
    <div
      v-for="image in images"
      :key="image.id"
      :class="[
        'gallery-container',
        `gallery-container-size-${SIZE_CLASSES[image.size]}`,
      ]"
    >
      <picture>
        <source
          v-for="imgType in createFormatArray(image.srcset)"
          :key="imgType.type"
          :type="imgType.type"
          :srcset="imgType.srcsetstring"
          :sizes="createSizes(image.size)"
        />
        <img
          role="none"
          class="gallery-image"
          :src="image.src || ''"
          :alt="image.alt"
          :loading="lazyLoad"
        />
      </picture>
    </div>
  </div>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"
  import cssVars from "~/assets/export.module.scss"
  import createSizesString from "~/lib/createSizesString"

  const SIZE_CLASSES = {
    0: "quarter",
    1: "third",
    2: "half",
    3: "two-thirds",
    4: "full",
  }

  const SIZE_TO_MODIFIER_MAP = {
    0: 3,
    1: 3,
    2: 2,
    3: 1.5,
    4: 1,
  }

  export default {
    mixins: [createFormatArray],
    props: {
      lazyLoad: {
        type: Boolean,
        default: false,
      },
      images: {
        type: Array,
        required: true,
      },
      inNarrowContent: {
        type: Boolean,
        default: false,
      },
      columnData: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return { SIZE_CLASSES, cssVars, SIZE_TO_MODIFIER_MAP }
    },
    computed: {
      modifier() {
        if (this.columnData) {
          if (this.columnData.columnLayout === "1") {
            return 2
          }
          if (this.columnData.columnLayout === "2") {
            if (this.columnData.columnPlacement === 1) {
              return 1.5
            }
            if (this.columnData.columnPlacement === 2) {
              return 3
            }
          }
        }
        if (this.inNarrowContent) {
          return 1.5
        }
        return 1
      },
      spacing() {
        return (
          (20 * (this.amountOfNonFullImages - 1)) / this.amountOfNonFullImages
        )
      },
      onlyOneImage() {
        return this.images.length === 1
      },
      containerWidth() {
        if (this.inNarrowContent) {
          return 852
        }
        return this.onlyOneImage
          ? cssVars.contentMaxWidth
          : cssVars.contentMaxWidth / this.modifier
      },
      amountOfNonFullImages() {
        return this.onlyOneImage
          ? 1
          : this.images.filter((img) => img.size !== 4).length
      },
    },
    methods: {
      getImagePercent(imageSize) {
        if (this.onlyOneImage) {
          return 1
        }
        return SIZE_TO_MODIFIER_MAP[imageSize]
      },
      createSizes(imageSize) {
        return createSizesString({
          max: {
            size:
              (this.containerWidth - 64) / this.getImagePercent(imageSize) -
              this.spacing,
          },
          lg: {
            modifier: `${this.modifier} / ${this.getImagePercent(imageSize)}`,
            spacing: this.spacing,
          },
          md: {
            modifier: `${this.modifier} / ${this.getImagePercent(imageSize)}`,
            spacing: this.spacing,
          },
          m: {
            modifier: `${this.modifier} / ${this.getImagePercent(imageSize)}`,
            spacing: this.spacing,
          },
          sm: {
            modifier: this.getImagePercent(imageSize),
            spacing: this.spacing,
          },
          mini: {
            modifier: this.getImagePercent(imageSize),
            spacing: this.spacing,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .gallery {
    display: flex;
    flex-flow: row wrap;
    grid-gap: $gap;
  }

  .gallery-container {
    flex-grow: 1;
    border-radius: $border-radius-large;
    overflow: hidden;
    box-shadow: $shadow;

    .gallery-image {
      position: relative;
      object-fit: cover;

      width: 100%;
      height: 100%;
    }

    &-size-quarter {
      flex-basis: calc(25% - $gap);
    }
    &-size-third {
      flex-basis: calc(33% - $gap);
    }
    &-size-two-thirds {
      flex-basis: calc(66% - $gap);
    }
    &-size-half {
      flex-basis: calc(50% - $gap);
    }
    &-size-full {
      flex-basis: 100%;
    }
  }
</style>
